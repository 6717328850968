<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header card-header-with-btn bg-light">
          <div class="card-title fs25">ACL Groups</div>
          <button
            v-if="isAuthorized(permissions.acl_group_create)"
            class="btn btn-sm btn-primary p-2 btn-resize-standard"
            @click="handleActionToggle"
          >
            <i class="icon-add"/>Add New
          </button>
        </div>
        <div class="card-body" v-loading="state.loading">

          <!--list-->
          <ItemTable
            :data="state.groups"
            :columns="state.columnMap"
            :show-actions="isAuthorized(permissionsGroup.aclGroups)"
          >
            <template #name="{item}">
              <div class="group-name">{{ item.name }}</div>
            </template>

            <template #permissions="{item}">
              <div class="permission-list" v-html="renderPermissions(item.permissions)" />
            </template>

            <template #action="{item}">
              <EditButton
                v-if="isAuthorized(permissions.acl_group_update)"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_UPDATE)"
              />

              <DeleteButton
                v-if="isAuthorized(permissions.acl_group_delete)"
                :loading="item.id === state.action.subject?.id && state.action.deleteLoading"
                @click="handleAction(item, constants.LIST_ITEM_ACTION_DELETE)"
              />
            </template>

          </ItemTable>

          <!--update Modal-->
          <GroupAction
            :show="state.showAction"
            :group="state.action.subject"
            :updating="state.action.types.edit"
            @hide="handleActionToggle"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import ButtonComp from '@/components/Util/ButtonComp';
import { useStore } from 'vuex';
import { constants } from '@/utils';
import GroupAction from '@/components/AccessControl/GroupAction';
import Toaster from '@/utils/Toaster';
import { isAuthorized } from '@/utils/Helper';
import permissions, { permissionsGroup } from '@/utils/permissions';
import EditButton from '@/components/Util/ListActions/EditButton';
import DeleteButton from '@/components/Util/ListActions/DeleteButton';
import SweetAlert from '@/utils/SweetAlert';

export default {
  name: 'Groups',
  components: { DeleteButton, EditButton, GroupAction, ItemTable, ButtonComp },
  setup () {

    const store = useStore();
    const userStore = store.state.acl.groups;

    const state = reactive({
      showAction: false,

      loading: false,

      groups: [],
      columnMap: [
        { field: 'name', header: 'Name' },
        { field: 'permissions', header: 'Permissions', sortable: true },
        { field: 'description', header: 'Description', sortable: true },
      ],

      action: {
        types: {
          status: false,
          edit: false,
          delete: false
        },
        subject: false,
        deleteLoading: false
      },
    });

    watch(userStore, (nv) => {
      state.groups = nv.data?.groups || [];
    });

    onMounted(async () => {
      state.loading = true;

      await store.dispatch('acl/fetchGroups');
      await store.dispatch('acl/fetchPermissions');

      state.loading = false;
    });

    const renderPermissions = (permissions) => {
      if (!permissions?.length) return 'N/A';

      let renderedPermissions = '';
      permissions.map(permission => renderedPermissions += `<code>${permission.name}</code>`);
      return renderedPermissions;
    };

    const handleActionToggle = (value) => {

      // set the desired action or just toggle
      const newValue = !!value || !state.showAction;

      state.showAction = newValue;

      // if closing the action then make edit false
      if (!newValue) {
        state.action.types.edit = false;
        state.action.subject = false;
      }

    };

    const handleDelete = (item) => {

      if (!isAuthorized(permissions.acl_group_delete)) return

      SweetAlert.confirmError({
        text: `Permanently Delete '${item.name}'?`,
        preConfirm: async (status) => {

          if (!status) return;

          state.action.deleteLoading = true;

          try {

            await store.dispatch('acl/deleteGroup', item.id);

            Toaster.successAlt({
              title: 'Deleted',
              message: `'${item.name}' has been deleted`
            })

          } catch (e) {
            Toaster.error({
              title: 'Deletion Failed',
              message: e.message
            });
          }

          state.action.deleteLoading = false;

        }
      });

    };

    const handleAction = (item, actionType) => {

      state.action.subject = item;

      switch (actionType) {
        case constants.LIST_ITEM_ACTION_UPDATE:
          if (!isAuthorized(permissions.acl_group_update)) return
          state.action.types.edit = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_UPDATE_STATUS:
          if (!isAuthorized(permissions.acl_group_update)) return
          state.action.types.delete = true;
          handleActionToggle();
          break;

        case constants.LIST_ITEM_ACTION_DELETE:
          if (!isAuthorized(permissions.acl_group_delete)) return
          state.action.types.delete = true;
          handleDelete(item);
          break;
      }

    };

    return {
      state,
      constants,
      renderPermissions,
      handleAction,
      handleActionToggle,
      isAuthorized,
      permissions,
      permissionsGroup
    };
  }
};
</script>

<style scoped>
.permission-list {
  display: flex;
  flex-direction: column;
}
.group-name {
  font-weight: bold;
}
</style>

<template>
  <ModalComp
    :title-text="state.modal.title"
    title-icon-class="icon-lock-open"
    :custom-class="state.modal.headerClasses"
    :loading="state.form.submitting"
    :show="state.modal.show"
    :mode="updating ? 'update' : 'create'"
    :ok-button-text="state.modal.submitBtnText"
    @hide="handleClose"
    @submit="handleSubmit"
  >

    <form class="row">
      <div class="form-group">
        <label for="groupName" class="col-form-label">
          Group Name:
          <span class="text-danger">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="groupName"
          v-model.trim="state.form.groupName.value"
          autocomplete="off"
        >
        <FormErrorMsg :error="state.form.groupName.error" />
      </div>
      <div class="form-group">
        <label for="permissions" class="col-form-label">Permissions:</label>
        <span class="text-danger">*</span>
        <TreeSelect
          id="permissions"
          placeholder="Select Allowed Permissions"
          v-model="state.form.permissionSelect.value"
          :options="state.form.permissionSelect.options"
          :multiple="true"
          :searchable="true"
          :clearable="true"
          :disableBranchNodes="true"
          :showCount="true"
        />
        <FormErrorMsg :error="state.form.permissionSelect.error" />
      </div>
      <div class="form-group">
        <label for="description" class="col-form-label">
          Description:
          <span class="text-danger">*</span>
        </label>
        <textarea
          class="form-control"
          id="description"
          v-model="state.form.description.value"
        />
        <FormErrorMsg :error="state.form.description.error" />
      </div>
    </form>

  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import ErrorHelper from '@/utils/ErrorHelper';
import Toaster from '@/utils/Toaster';
import collect from 'collect.js';

export default {
  name: 'GroupAction',
  components: { ModalComp, FormErrorMsg, FormInputHelp, SwitchComp, TreeSelect },
  emits: ['hide'],
  props: {
    group: {
      type: [Object, Boolean],
      default: false
    },
    updating: Boolean,
    show: {
      type: Boolean,
      default: false
    },
  },

  setup (props, { emit }) {

    const store = useStore();

    const permissionState = store.state.acl.permissions;

    const state = reactive({
      modal: {
        show: false,
        title: 'Create New Group',
        submitBtnText: 'Add Group'
      },
      form: {
        groupName: {
          value: '',
          error: false
        },
        permissionSelect: {
          value: [],
          options: [],
          error: false
        },
        description: {
          value: '',
          error: false
        },
        submitting: false
      },
    });

    state.form.permissionSelect.options = computed(() => {
      const list = permissionState.data.permissions;

      if (!list) return [];

      const options = collect(list)
        .groupBy('subject')
        .map((permissionGroup, subject) => {
          const parentGroup = {
            id: subject,
            label: subject,
            children: []
          };

          // include child's in parent
          permissionGroup.map(permission => {
            parentGroup.children.push({
              id: permission.id,
              label: permission.name,
            });
          });

          return parentGroup;
        })
      ;

      return options.toArray();
    });

    watch(() => props.show, (nv) => {
      state.modal.show = nv;
    });

    watch(() => props.updating, (nv) => {

      // reset the form if updating
      if (!nv) {
        // reset form
        resetForm();

        state.modal = {
          ...state.modal,
          title: 'Create New Group',
          submitBtnText: 'Add Group'
        };

        return;
      }

      // or customize modal
      state.modal = {
        ...state.modal,
        title: 'Update Group',
        submitBtnText: 'Save Changes'
      };

      // populate values
      state.form.groupName.value = props.group.name;
      state.form.permissionSelect.value = props.group.permissions.map(permission => permission.id);
      state.form.description.value = props.group.description;
    });

    // validation start
    const validationRules = {
      form: {
        groupName: {
          value: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(20)
          }
        },
        permissionSelect: {
          value: {
            required
          }
        },
        description: {
          value: {
            maxLength: maxLength(50)
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.groupName.error = errors['form.groupName.value'] || false;
          state.form.permissionSelect.error = errors['form.permissionSelect.value'] || false;
          state.form.description.error = errors['form.description.value'] || false;
        },
        {
          'form.groupName.value': 'Group Name',
          'form.permissionSelect.value': 'Permissions',
          'form.description.value': 'Description'
        }
      );
    });
    // validation end

    const resetForm = () => {
      state.form.groupName.value = '';
      state.form.permissionSelect.value = null;
      state.form.description.value = '';
    };

    function handleClose () {
      emit('hide', false);
    }

    async function handleSubmit () {

      if (!ErrorHelper.checkValidity(validator)) return;

      const data = {
        name: state.form.groupName.value,
        permissions: state.form.permissionSelect.value,
        description: state.form.description.value
      };

      state.form.submitting = true;

      try {

        if (props.updating) {
          await store.dispatch('acl/updateGroup', { id: props.group.id, data });
        } else {
          await store.dispatch('acl/createGroup', data);
        }

        // close the modal
        handleClose();

        Toaster.successAlt({
          title: `Group ${props.updating ? 'Updated' : 'Created'}`,
          message: `${props.group.name} has been ${props.updating ? 'updated' : 'created'}`
        });

      } catch (e) {

        Toaster.error({
          title: 'Failed',
          message: e.message
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.groupName.error = ex(err.name);
          state.form.permissionSelect.error = ex(err.permissions);
          state.form.description.error = ex(err.description);
        });

      }

      state.form.submitting = false;
    }

    return {
      state,
      handleClose,
      handleSubmit,
    };
  }
};
</script>
